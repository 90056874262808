import "esri-leaflet-renderers";

import { FeatureLayer } from "react-esri-leaflet";
import {
  ContextualLayer as LayerType,
  ContextualLayerGroup as GroupType,
} from "components/fetch/useContextualLayersQuery";
import { useSelectedContextualFeature } from "components/layers/SelectedContextualFeatureProvider";
import { useContextualLayers } from "components/layers/ContextualLayerProvider";
import slugify from "slugify";

const ContextualLayerGroup = ({ layerGroup }: { layerGroup: GroupType }) => {
  const { setSelectedContextualFeature, setLayerKey, setLayerName } =
    useSelectedContextualFeature();
  const { isLayerVisible, getLayerKey } = useContextualLayers();

  return (
    <>
      {layerGroup.layers?.map((layer: LayerType) => {
        const layerKey = getLayerKey(layerGroup, layer);
        if (!isLayerVisible(layerKey)) return null;
        if (layer.service === "ESRI") {
          const where =
            layer.feature_filter?.filterFieldName &&
            layer.feature_filter?.typeId
              ? `${layer.feature_filter.filterFieldName}=${layer.feature_filter.typeId}`
              : "1=1";
          return (
            <FeatureLayer
              key={layerKey}
              url={layer.base_url}
              where={where}
              // Canvas was blocking click on projects on vector tiles.
              // Less performant but svg is better for this use case.
              // renderer={new L.Canvas()}
              eventHandlers={{
                click: (e: any) => {
                  setLayerKey(layerKey);
                  setLayerName(layerGroup.name + " " + layer.name);
                  setSelectedContextualFeature(e.layer?.feature);
                },
              }}
            />
          );
        }
        // Currently no contextual layers use other services (WFS, WMS)
        // So we don't implement them for now.
        return null;
      }) ?? null}
    </>
  );
};

export const ContextualLayer = () => {
  const { contextualLayers } = useContextualLayers();

  return (
    <>
      {contextualLayers &&
        contextualLayers.map((layerGroup) => {
          return (
            <ContextualLayerGroup
              key={`layer-group-${slugify(layerGroup.name)}`}
              layerGroup={layerGroup}
            />
          );
        })}
    </>
  );
};
