import { useContextualLayers } from "components/layers/ContextualLayerProvider";
import {
  CascadingCheckboxAccordion,
  CHECKBOX_STATES,
} from "./CascadingCheckboxAccordion";
import { slugify } from "utils/slugify";
import { ESRIMapServerLegend } from "./ESRIMapServerLegend";
import { ESRIFeatureServerLegend } from "./ESRIFeatureServerLegend";
import { CascadingAccordion } from "./CascadingAccordion";
import { Alert } from "react-bootstrap";

export const ContextualLayers = () => {
  const {
    contextualLayers,
    isLoading,
    isError,
    toggleCheckState,
    isLayerVisible,
    getLayerKey,
  } = useContextualLayers();

  return (
    <>
      {isLoading && <p>Loading...</p>}
      {isError && (
        <Alert variant="danger">
          Error fetching contextual layers. Please refresh the page and try
          again later.
        </Alert>
      )}
      {contextualLayers.length > 1 &&
        contextualLayers.map((group) => {
          return (
            <CascadingAccordion
              key={`layer-group-${slugify(group.name)}`}
              id={`layer-group-${slugify(group.name)}`}
              title={group.name}
              indentation={false}
            >
              {group.layers?.map((layer) => {
                const layerKey = getLayerKey(group, layer);
                return (
                  <CascadingCheckboxAccordion
                    key={getLayerKey(group, layer)}
                    id={`layer-${layerKey}`}
                    title={layer.name}
                    checked={
                      isLayerVisible(layerKey)
                        ? CHECKBOX_STATES.Checked
                        : CHECKBOX_STATES.Empty
                    }
                    toggleChecked={() => {
                      toggleCheckState(group, layer);
                    }}
                  >
                    {layer.base_url.includes("MapServer") && (
                      <ESRIMapServerLegend layer={layer} layerKey={layerKey} />
                    )}
                    {layer.base_url.includes("FeatureServer") && (
                      <ESRIFeatureServerLegend
                        layer={layer}
                        layerKey={layerKey}
                      />
                    )}
                  </CascadingCheckboxAccordion>
                );
              })}
            </CascadingAccordion>
          );
        })}
    </>
  );
};
