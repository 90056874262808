import { Formik, Form } from "formik";

import { buildFormConfig } from "utils/form";
import {
  useMyOrganisationFilter,
  accordionId,
  MyOrganisationFilterValue,
  defaultFilterValue,
} from "./MyOrganisationFilterContext";
import { ContactName, useContactNameConfig } from "./formFields/ContactName";
import { NoBoundary, noBoundaryConfig } from "./formFields/NoBoundary";
import { LinkButton } from "components/button/LinkButton";
import { useMyOrganisationsProjects } from "components/project/my-organisation/MyOrganisationsProjectsContext";
import { useNavigate } from "react-router-dom";
import { InternalOnly, internalOnlyConfig } from "./formFields/InternalOnly";
import { SharedOnly, sharedOnlyConfig } from "./formFields/SharedOnly";

export const MyOrganisationFilterForm = () => {
  const { updateFilter, resetFilterValue } = useMyOrganisationFilter();

  const contactNameConfig = useContactNameConfig();
  const { initialValues, validationSchema } = buildFormConfig(
    contactNameConfig,
    noBoundaryConfig,
    internalOnlyConfig,
    sharedOnlyConfig
  );

  const { setFilter } = useMyOrganisationsProjects();

  const navigate = useNavigate();

  const onSubmit = (values: MyOrganisationFilterValue) => {
    updateFilter(values);

    // If there is a page param, remove it so that the filtered results can
    // start at page 1:
    const urlParams = new URLSearchParams(document.location.search);
    if (urlParams.get("page")) {
      navigate("/my-organisation");
    }

    setFilter(values);
  };

  return (
    <div className="accordion">
      <div id={accordionId} className="accordion-collapse collapse">
        <div className="accordion-body px-2 py-3 gray-bg mb-3">
          <div className="h6">Filter projects by</div>
          <Formik
            initialValues={initialValues as MyOrganisationFilterValue}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ resetForm }) => (
              <Form>
                <ContactName />
                <NoBoundary />
                <InternalOnly />
                <SharedOnly />

                <div className="d-flex align-items-baseline justify-content-end gap-3">
                  <LinkButton
                    type="button"
                    onClick={() => {
                      // clear both the form and the applied filter
                      resetForm();
                      resetFilterValue();

                      /**
                       * When the form is cancelled, revert the list of projects
                       * back to the unfiltered list that appears by default,
                       * even if there was previously another filter applied.
                       * This should be a list of all published projects for
                       * the organisation:
                       */
                      setFilter(defaultFilterValue);
                    }}
                    data-bs-toggle="collapse"
                    data-bs-target={`#${accordionId}`}
                    aria-controls={accordionId}
                  >
                    Cancel
                  </LinkButton>
                  <button type="submit" className="btn btn-primary mt-3">
                    Apply filter
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
