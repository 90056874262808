import { useSelectedContextualFeature } from "components/layers/SelectedContextualFeatureProvider";
import { useEffect } from "react";
import { useContextualLayers } from "components/layers/ContextualLayerProvider";
import { useMap } from "react-leaflet";
import { Map } from "leaflet";
import "./ContextualLayerPopup.scss";

const setMapDraggable = (state: boolean, map: Map) => {
  if (state) {
    map.dragging.enable();
    map.doubleClickZoom.enable();
  } else {
    map.dragging.disable();
    map.doubleClickZoom.disable();
  }
};

export const ContextualLayerPopup = () => {
  const {
    selectedContextualFeature,
    setSelectedContextualFeature,
    layerKey,
    layerName,
  } = useSelectedContextualFeature();
  const { isLayerVisible } = useContextualLayers();
  const isVisible = isLayerVisible(layerKey);
  const map = useMap();

  useEffect(() => {
    if (selectedContextualFeature && !isVisible) {
      setSelectedContextualFeature(null);
    }
  }, [selectedContextualFeature, isVisible, setSelectedContextualFeature]);

  return selectedContextualFeature ? (
    <div
      className="contextual-layer-popup leaflet-control"
      onMouseOver={() => setMapDraggable(false, map)}
      onMouseOut={() => setMapDraggable(true, map)}
    >
      <div className="title fw-700 d-flex flex-row justify-content-between">
        <span className="h5 mb-0">{layerName}</span>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => {
            setSelectedContextualFeature(null);
            setMapDraggable(true, map);
          }}
        ></button>
      </div>
      <div className="description">
        {selectedContextualFeature.properties ? (
          <table className="w-100">
            <tbody>
              {Object.keys(selectedContextualFeature.properties).map((key) => (
                <tr key={key}>
                  <th className="pe-2">{key}</th>
                  <td>
                    {selectedContextualFeature.properties &&
                      selectedContextualFeature.properties[key]}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="text-danger">
            Properties for the selected feature are missing.
          </div>
        )}
      </div>
    </div>
  ) : null;
};
