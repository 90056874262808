import * as Yup from "yup";
import { FormFieldConfig } from "utils/types/form";
import { CheckBox } from "components/form/CheckBox";
import { defaultFilterValue } from "../MyOrganisationFilterContext";

const fieldName = "internal_only";

export const internalOnlyConfig: FormFieldConfig = {
  name: fieldName,
  initialValue: defaultFilterValue.internal_only,
  validationSchema: Yup.boolean(),
};

export const InternalOnly = () => {
  return <CheckBox label="Internal projects" name={fieldName} />;
};
