import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

export interface MyOrganisationFilterValue extends Record<string, any> {
  contact: string;
  internal_only: boolean;
  shared_only: boolean;
  no_geom_only: boolean;
}

export type FieldName = keyof MyOrganisationFilterValue;

interface IsFieldActive {
  [key: FieldName]: boolean;
}

interface MyOrganisationFilterContextValue {
  /**
   * Filter values that's currently applied.
   */
  filterValue: MyOrganisationFilterValue;

  /**
   * Gets active state of the field.
   */
  getIsActive: (fieldName: FieldName) => boolean;

  /**
   * Sets active state of the given key.
   */
  setIsActive: (fieldName: FieldName, isActive: boolean) => void;

  /**
   * Reset filter value to default.
   */
  resetFilterValue: () => void;

  /**
   * Updates filterValue based on active state and the passed values.
   */
  updateFilter: (values: MyOrganisationFilterValue) => void;
}

export const defaultFilterValue: MyOrganisationFilterValue = {
  contact: "",
  internal_only: false,
  shared_only: false,
  no_geom_only: false,
};

const defaultActive: IsFieldActive = {
  contact: false,
  no_geom_only: true, // checkbox field must always be true
};

export const MyOrganisationFilterContext = createContext(
  {} as MyOrganisationFilterContextValue
);

export const useMyOrganisationFilter = () =>
  useContext(MyOrganisationFilterContext);

export const MyOrganisationFilterProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  // This represents the filter fields when applied (after submitting form)
  const [filterValue, setFilterValue] =
    useState<MyOrganisationFilterValue>(defaultFilterValue);

  // This represents the state of the checkbox next to filter fields (before
  // submitting filter form). Ideally we'd move this into Formik state since the
  // checkboxes are part of the filter form, not the applied filter, and it
  // confusing to co-locate some unapplied state together with the applied
  // filter.
  const [active, setActive] = useState<IsFieldActive>(defaultActive);

  const getIsActive = useCallback(
    (fieldName: FieldName) => {
      return active[fieldName];
    },
    [active]
  );

  const setIsActive = useCallback((fieldName: FieldName, isActive: boolean) => {
    setActive((prev) => ({
      ...prev,
      [fieldName]: isActive,
    }));
  }, []);

  const resetFilterValue = useCallback(() => {
    setFilterValue(defaultFilterValue);
    setActive(defaultActive);
  }, []);

  // Apply all fields when submitting the filter form, not just active fields,
  // since unchecking these will clear at the time of unchecking.
  const updateFilter = useCallback((values: MyOrganisationFilterValue) => {
    setFilterValue((prev) => {
      const newFilterValue = { ...prev };
      Object.entries(newFilterValue).forEach(([fieldName]) => {
        newFilterValue[fieldName] = values[fieldName];
      });
      return newFilterValue;
    });
  }, []);

  const contextValue = useMemo(
    () => ({
      filterValue,
      getIsActive,
      setIsActive,
      resetFilterValue,
      updateFilter,
    }),
    [filterValue, getIsActive, resetFilterValue, setIsActive, updateFilter]
  );

  return (
    <MyOrganisationFilterContext.Provider value={contextValue}>
      {children}
    </MyOrganisationFilterContext.Provider>
  );
};

export const accordionId = "my-org-filter-accordion";
