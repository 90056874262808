import { ContextualLayerGroup } from "components/fetch/useContextualLayersQuery";

const NATIONAL = "NATIONAL";

export function contextualLayerSortingFn(
  a: ContextualLayerGroup,
  b: ContextualLayerGroup
) {
  const nameA = a.name.toUpperCase(); // ignore upper and lowercase
  const nameB = b.name.toUpperCase();
  // float national layer group always to top
  if (nameA === NATIONAL && nameB !== NATIONAL) {
    return -1;
  }
  if (nameA !== NATIONAL && nameB === NATIONAL) {
    return 1;
  }
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}
