import classNames from "classnames";

interface Props {
  id: string;
  title: React.ReactNode;
  indentation?: boolean;
  children: React.ReactNode;
}

export const CascadingAccordion = ({
  id,
  title,
  indentation = true,
  children,
}: Props) => (
  <div className={classNames("accordion", { "ms-3": indentation })}>
    <div className="d-flex justify-content-between align-items-center py-1 rounded">
      <label htmlFor={id} className="flex-grow-1">
        {title}
      </label>
      <button
        className="accordion-button collapsed w-auto p-0"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target={`#${id}`}
        aria-expanded="false"
        aria-controls={id}
        aria-label={`Toggle ${title} accordion`}
      ></button>
    </div>
    <div id={id} className="accordion-collapse collapse">
      <div className="accordion-body p-0">{children}</div>
    </div>
  </div>
);
