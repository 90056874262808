import { createContext, useContext, useEffect, useMemo, useState } from "react";

import {
  ContextualLayer,
  ContextualLayers,
  ContextualLayerGroup,
  useContextualLayersQuery,
} from "components/fetch/useContextualLayersQuery";
import { slugify } from "utils/slugify";
import { contextualLayerSortingFn } from "utils/contextualLayerSort";

export type CheckStates = {
  [key: string]: boolean;
};

interface LayersContextInterface {
  contextualLayers: ContextualLayers;
  isLoading: boolean;
  isError: boolean;
  toggleCheckState: (
    layerGroup: ContextualLayerGroup,
    layer: ContextualLayer
  ) => void;
  isLayerVisible: (layerKey: string) => boolean;
  getLayerKey: (
    layerGroup: ContextualLayerGroup,
    layer: ContextualLayer
  ) => string;
}

export const ContextualLayersContext = createContext<LayersContextInterface>(
  null!
);

export const ContextualLayersProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { data, isLoading, isError } = useContextualLayersQuery();

  const [layerVisibility, setLayerVisibility] = useState<CheckStates>(
    {} as CheckStates
  );

  const sortLayerGroups = (layerGroups: ContextualLayers) => {
    if (layerGroups.length === 0) return;
    return [...layerGroups].sort(contextualLayerSortingFn);
  };

  const contextualLayers = useMemo(() => {
    if (!data) return [];
    return sortLayerGroups(data) as ContextualLayers;
  }, [data]);

  const isLayerVisible = (layerKey: string) => {
    return layerVisibility[layerKey];
  };

  const getLayerKey = (
    layerGroup: ContextualLayerGroup,
    layer: ContextualLayer
  ) => {
    return slugify(`${layerGroup.name} ${layer.name}`);
  };

  const toggleCheckState = (
    layerGroup: ContextualLayerGroup,
    layer: ContextualLayer
  ) => {
    const layerKey = getLayerKey(layerGroup, layer);
    setLayerVisibility((prev) => ({
      ...prev,
      [layerKey]: !prev[layerKey],
    }));
  };

  useEffect(() => {
    if (!contextualLayers) return;

    const layerVisibility = contextualLayers.reduce(
      (accumulator, layerGroup) => {
        const visibility = Object.create({}) as CheckStates;
        for (const layer of layerGroup.layers) {
          // Create key based on layer group name + layer name
          // use key to track layer toggle state
          const layerKey = getLayerKey(layerGroup, layer);
          visibility[layerKey] = false;
        }
        return {
          ...accumulator,
          ...visibility,
        };
      },
      {}
    );

    setLayerVisibility(layerVisibility);
  }, [contextualLayers]);

  const value = {
    contextualLayers,
    isLoading,
    isError,
    toggleCheckState,
    isLayerVisible,
    getLayerKey,
  };

  return (
    <ContextualLayersContext.Provider value={value}>
      {children}
    </ContextualLayersContext.Provider>
  );
};

export const useContextualLayers = () => {
  return useContext(ContextualLayersContext);
};
