import * as Yup from "yup";
import { FormFieldConfig } from "utils/types/form";
import { CheckBox } from "components/form/CheckBox";
import { defaultFilterValue } from "../MyOrganisationFilterContext";

const fieldName = "shared_only";

export const sharedOnlyConfig: FormFieldConfig = {
  name: fieldName,
  initialValue: defaultFilterValue.shared_only,
  validationSchema: Yup.boolean(),
};

export const SharedOnly = () => {
  return <CheckBox label="Internal - shared projects" name={fieldName} />;
};
